import { Select, SelectItem } from '@hubportal/components';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePickerPriority } from 'utils/network/apis';
import useStore from 'utils/store';

const PICKING_PRIORITY_OPTIONS = ['1', '2', '3', '4', '5'] as const;
type PickingPriority = typeof PICKING_PRIORITY_OPTIONS[number];

interface PickingPriorityColumnProps {
  employeeId: string;
  pickerPriority?: number | null;
}

export default function PickingPriorityColumn({
  employeeId,
  pickerPriority,
}: PickingPriorityColumnProps): JSX.Element {
  const {
    selectedHub: { slug: hubSlug },
  } = useStore();

  const [selectedPriority, setSelectedPriority] = useState<
    PickingPriority | '-'
  >('-');

  useEffect(() => {
    setSelectedPriority(
      pickerPriority ? (pickerPriority.toString() as PickingPriority) : '-'
    );
  }, [pickerPriority]);

  const queryClient = useQueryClient();
  const { mutate: mutatePickerPriority, isLoading } = useMutation({
    mutationFn: (newPriority: number) =>
      updatePickerPriority(hubSlug, employeeId, newPriority),
    onSuccess: () =>
      queryClient.invalidateQueries(['get_ops_associates', hubSlug]),
  });

  const handlePriorityChange = (newPriority: string): void => {
    if (newPriority === pickerPriority?.toString()) {
      return;
    }
    setSelectedPriority(newPriority as PickingPriority);
    const updatedPriority = Number(newPriority);
    mutatePickerPriority(updatedPriority);
  };

  return (
    <div className="flex h-full items-center">
      <div className="w-[74px] bg-primary rounded-lg">
        <Select
          data-testid="pickingPrioritySelect"
          label=""
          placeholder=""
          value={{ id: selectedPriority, label: selectedPriority }}
          onChange={({ id }) => handlePriorityChange(id as string)}
          disabled={isLoading}
        >
          {PICKING_PRIORITY_OPTIONS.map((priority) => (
            <SelectItem id={priority} label={priority} key={priority}>
              {priority}
            </SelectItem>
          ))}
        </Select>
      </div>
    </div>
  );
}
