import {
  UserTrackingServiceApi,
  V1ExperimentLookupRequest,
  V1Platform,
} from '@flink/experiment';
import { getUser } from 'utils/auth';

const BASE_URL = process.env.REACT_APP_AUTH0_AUDIENCE;

const api = new UserTrackingServiceApi(
  {},
  `${BASE_URL}/user-tracking-gateway/user-tracking-gateway`
);

export const eppo = async (flags: string[], attributes: any): Promise<any> => {
  const user = await getUser();
  const body: V1ExperimentLookupRequest = {
    userId: user?.sub,
    meta: {
      attributes: attributes,
    },
    flags: flags,
    platform: V1Platform.EppoOps,
  };

  try {
    return await api.userTrackingServiceExperimentLookup(body);
  } catch (error) {
    throw error;
  }
};
