import { useEffect } from 'react';
import { Icon } from '@hubportal/components';
import useStore from 'utils/store';
import { TooltipHeader } from 'components/TooltipHeader';
import { useTranslation } from 'react-i18next';

const FullScreen = () => {
  const { t } = useTranslation();
  const { isFullScreen, toggleFullScreen } = useStore((state) => ({
    isFullScreen: state.isFullScreen,
    toggleFullScreen: state.toggleFullScreen,
  }));

  const fullScreenClickHandler = () => {
    // Reference
    // https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API#examples
    if (!document.fullscreenElement) {
      toggleFullScreen();
    }
  };

  const onClick = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      toggleFullScreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    document.addEventListener(
      'fullscreenchange',
      fullScreenClickHandler,
      false
    );
    return () => {
      document.removeEventListener(
        'fullscreenchange',
        fullScreenClickHandler,
        false
      );
    };
  }, []);
  return (
    <div
      onClick={onClick}
      className="flex justify-center items-center hover:cursor-pointer"
    >
      <span className="text-[#BFBFBF] mr-1 ml-2 bg-primary p-2 rounded-lg">
        {!isFullScreen && (
          <TooltipHeader label="" info={t('fullscreen_enter')}>
            <Icon type="tvView" />
          </TooltipHeader>
        )}
      </span>
    </div>
  );
};

export default FullScreen;
