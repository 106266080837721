export const isFeatureEnabledEppo = (
  allFlags,
  featureFlag,
  variant
): boolean => {
  if (Object.keys(allFlags).length && allFlags.decisions[featureFlag]) {
    const flagVariation = allFlags.decisions[featureFlag].variation;
    if (flagVariation && flagVariation === variant) {
      return true;
    }
  }
  return false;
};
