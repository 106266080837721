import { Permission } from 'utils/hooks/usePermissions';
import create from 'zustand';

type Country = 'DE' | 'AT' | 'FR' | 'NL';

type Permissions = {
  [key in Permission]?: boolean;
};

export enum ActivitySections {
  EMPLOYEE = 'employee',
  HUB = 'hub',
}

type Store = {
  country: Country;
  selectedHub: { slug: string; name: string };
  hubs: { id: string }[];
  countries: { id: string }[];
  bundleStatusChangeError: string;
  permissions: Permissions;
  permissionsReady: boolean;
  activitySection: string;
  isFullScreen: boolean;
  allowedHubs: string[];
  setPermissions: (permissions: Permissions) => void;
  setBundleStatusChangeError: (statusChangeError: string) => void;
  setHubs: (hubs: { id: string }[]) => void;
  setCountry: (country: Country) => void;
  setSelectedHub: (hub: { slug: string; name: string }) => void;
  setActivitySection: (activitySection: ActivitySections) => void;
  toggleFullScreen: (close?: boolean) => void;
  featureFlagDecisions: object;
  setFeatureFlagDecisions: (featureFlagDecisions: any) => void;
  setAllowedHubs: (hubs: string[]) => void;
};

const useStore = create<Store>()((set) => ({
  country: 'DE',
  selectedHub: { slug: '', name: '' },
  hubs: [],
  countries: [{ id: 'de' }, { id: 'at' }, { id: 'fr' }, { id: 'nl' }],
  bundleStatusChangeError: '',
  permissions: {},
  permissionsReady: false,
  allowedHubs: [],
  activitySection: ActivitySections.HUB,
  setBundleStatusChangeError: (bundleStatusChangeError) =>
    set({ bundleStatusChangeError }),
  setHubs: (hubs) => set({ hubs }),
  setCountry: (country) => set({ country }),
  setSelectedHub: (selectedHub) => set({ selectedHub }),
  setPermissions: (permissions) => set({ permissions, permissionsReady: true }),
  setActivitySection: (activitySection) => set({ activitySection }),
  isFullScreen: false,
  toggleFullScreen: () =>
    set((state) => ({ isFullScreen: !state.isFullScreen })),
  featureFlagDecisions: {},
  setFeatureFlagDecisions: (featureFlagDecisions) =>
    set({ featureFlagDecisions }),
  setAllowedHubs: (allowedHubs) => set({ allowedHubs }),
}));

export default useStore;
export const { getState } = useStore;
