import { useMemo } from 'react';
import { Icon } from '@hubportal/components';
import { useTranslation } from 'react-i18next';

import { Card, CardItem, CardRowItem } from 'components/CardV2';
import { isFeatureEnabledEppo } from 'utils/eppo/helpers';
import { FEATURE_FLAGS, FEATURE_VARIANTS } from 'utils/eppo/flags';
import useStore from 'utils/store';
import { EmployeePriorityMap } from 'src/hooks/useEmployeePriorityMap';

const OpsAssociates = ({
  data,
  employeePriorityMap,
}: {
  data: any;
  employeePriorityMap: EmployeePriorityMap;
}): JSX.Element => {
  const { t } = useTranslation();
  const { featureFlagDecisions } = useStore();
  const isPickingPriorityEnabled = isFeatureEnabledEppo(
    featureFlagDecisions,
    FEATURE_FLAGS.OF_PICKING_PRIORITY,
    FEATURE_VARIANTS.ON
  );

  const cards = useMemo(() => {
    if (!data) {
      return [];
    }

    const unsortedCards = [
      {
        icon: 'idle',
        title: t('ops.idle.title'),
        emptyText: t('ops.idle.inactive'),
        data: data.idle,
      },
      {
        icon: 'picking',
        title: t('ops.picking.title'),
        emptyText: t('ops.picking.inactive'),
        data: data.picking,
      },
      {
        icon: 'inbounding',
        title: t('ops.inbounding.title'),
        emptyText: t('ops.inbounding.inactive'),
        data: data.inbounding,
      },
      {
        icon: 'restocking',
        title: t('ops.restocking.title'),
        emptyText: t('ops.restocking.inactive'),
        data: data.restocking,
      },
      {
        icon: 'inventory',
        title: t('ops.inventory.title'),
        emptyText: t('ops.inventory.inactive'),
        data: data.inventory,
      },
      {
        icon: 'hubActivities',
        title: t('ops.hub_activities.title'),
        emptyText: t('ops.hub_activities.inactive'),
        data: data.hub_activities,
      },
    ];

    const activeCards = unsortedCards.filter((card) => card.data.length > 0);
    const inactiveCards = unsortedCards.filter(
      (card) => card.data.length === 0
    );

    return [...activeCards, ...inactiveCards];
  }, [data]);

  return (
    <div className="flex flex-col h-full">
      <div className="">
        {cards.map((card, i) => {
          return (
            <Card key={i}>
              <CardItem
                icon={
                  <Icon
                    type={
                      card.icon as
                        | 'inbounding'
                        | 'inventory'
                        | 'picking'
                        | 'idle'
                    }
                    className="w-[1.65vw] h-[1.65vw]"
                  />
                }
                item={card.title}
                value={card.data.length}
              />
              <div
                className={`${
                  card.data.length > 0 ? 'flex flex-col py-[0.56vw]' : 'hidden'
                }`}
              >
                {card.data.map((item) => {
                  return (
                    <CardRowItem
                      key={item.employee_number}
                      item={`${item.first_name} ${
                        item.last_name && item.last_name.substring(0, 1)
                      }.`}
                      value={item.in_state_since}
                      pickingSpeed={
                        item.avg_picking_speed_per_item_in_seconds ?? 0
                      }
                      employeePriority={
                        isPickingPriorityEnabled
                          ? employeePriorityMap[item.employee_id]
                          : null
                      }
                    />
                  );
                })}
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default OpsAssociates;
