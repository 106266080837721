import { Icon } from '@hubportal/components';
import { Link } from 'react-router-dom';

interface Metric {
  title: string;
  value: number | string;
  link: string;
}

interface MetricCardProps {
  mainMetric: Metric;
  otherMetrics: Metric[];
  urgentCheckCount?: number;
  hasRider?: boolean;
  setInfoMoreModal?: () => void;
  setRiderInfoMoreModalClose?: () => void;
  title?: string;
}

export function MetricCard({
  mainMetric,
  otherMetrics,
  hasRider,
  setInfoMoreModal,
  title,
}: MetricCardProps): JSX.Element {
  return (
    <div className="h-full ">
      <div
        className={`flex flex-col h-full justify-start
          ${hasRider ? 'rounded-l-lg' : 'rounded-lg'}
        `}
      >
        <div className="p-4 justify-center text-white">
          <div className="flex text-[#BFBFBF] title-s">
            <span className="mr-1">{title}</span>
            <Icon
              type="infoCircle"
              onClick={() => setInfoMoreModal && setInfoMoreModal()}
            />
          </div>
          <Link
            to={mainMetric.link}
            reloadDocument
            className="flex justify-center flex-col items-left my-6 px-2"
          >
            <div className="text-4xl font-bold">{mainMetric.value}</div>
            <div className="text-xl mt-2">{mainMetric.title}</div>
          </Link>

          <div className="grid grid-cols-2">
            {otherMetrics.map((metric, i) => {
              const MetricContent = (
                <div className="py-4 px-2">
                  <div className="text-2xl font-bold">{metric.value}</div>
                  <div className="mt-1.5 text-base text-[#BFBFBF]">
                    {metric.title}
                  </div>
                </div>
              );

              return metric.link ? (
                <Link key={i} to={metric.link} reloadDocument>
                  {MetricContent}
                </Link>
              ) : (
                <div key={i}>{MetricContent}</div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
