import { Icon, Tab, Tabs, TabsVariant } from '@hubportal/components';
import useStore, { ActivitySections } from 'utils/store';
import { useEffect, useMemo, useState } from 'react';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import HubLevel from '../hub-level';
import { useTranslation } from 'react-i18next';
import { Countries } from 'utils/constants';
import { Link } from 'react-router-dom';
import { useCurrentPath } from 'utils/hooks/useCurrentPath';
import OrdersStatsWidget from 'components/OrdersStats';
import FullScreen from '../hub-level/full-screen';

const EMPLOYEE_LEVEL_TAB = '/activity/employee-level';

const FlowManagementHubLevel = ({
  authClient,
}: PropsWithAuth0Client): JSX.Element => {
  const {
    country,
    selectedHub,
    activitySection,
    setActivitySection,
    isFullScreen,
    toggleFullScreen,
  } = useStore();
  const { isAllowed } = usePermissions();
  const { t } = useTranslation();
  const { path: currentPath } = useCurrentPath();

  function formatTime(date: Date): string {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  const [lastUpdated, setLastUpdated] = useState<string>(
    formatTime(new Date())
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setLastUpdated(formatTime(new Date()));
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const isEmployeeActivityAllowed =
    country === Countries.NL || isAllowed(Permission.EMPLOYEE_ACTIVITY);

  const currentTab = useMemo(() => {
    return currentPath === EMPLOYEE_LEVEL_TAB
      ? ActivitySections.EMPLOYEE
      : ActivitySections.HUB;
  }, [currentPath]);

  useEffect(() => {
    if (currentPath === EMPLOYEE_LEVEL_TAB) {
      setActivitySection(ActivitySections.EMPLOYEE);
    } else {
      setActivitySection(ActivitySections.HUB);
    }
  }, [currentPath, setActivitySection]);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (): void => {
    if (!isFullScreen || isHovered) {
      return;
    }
    setIsHovered(true);
  };

  const handleMouseLeave = (): void => {
    if (!isFullScreen || !isHovered) {
      return;
    }
    setIsHovered(false);
  };

  const exitFullscreen = (): void => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      toggleFullScreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  return (
    <>
      <div className="relative">
        <div className="h-36 flex justify-between mx-10">
          <div
            className={`${
              isFullScreen ? 'hidden' : 'flex flex-col justify-end'
            }`}
          >
            <div className="flex flex-col justify-center header-m text-white whitespace-nowrap">
              <div
                className={`${
                  isFullScreen
                    ? 'none'
                    : 'flex text-center justify-center items-center'
                }`}
              >
                {!isFullScreen && (
                  <span className="mr-2">{selectedHub?.name}</span>
                )}
                {activitySection === ActivitySections.HUB && <FullScreen />}
              </div>

              {activitySection === ActivitySections.HUB && !isFullScreen && (
                <div className="text-xs font-normal h-4">
                  {t('hub_level.last_updated')} {lastUpdated.toLocaleString()}
                </div>
              )}
            </div>
            {!isFullScreen && (
              <div className="pt-4">
                <Tabs
                  value={currentTab}
                  onChange={() => {}}
                  variant={TabsVariant.secondary}
                >
                  <Tab id={ActivitySections.HUB}>
                    <Link reloadDocument to="/activity">
                      {t('workforce_kpi.hub_level.tab.title')}
                    </Link>
                  </Tab>

                  {isEmployeeActivityAllowed && (
                    <Tab id={ActivitySections.EMPLOYEE}>
                      <Link reloadDocument to="/activity/employee-level">
                        {t('workforce_kpi.employee.tab.title')}
                      </Link>
                    </Tab>
                  )}
                </Tabs>
              </div>
            )}
          </div>

          {isFullScreen && (
            <div
              className="absolute bg-transparent hover:bg-opacity-20 z-10"
              style={{
                width: '50%',
                height: '50%',
                top: '0',
                right: '0',
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {isHovered && (
                <div
                  onClick={() => exitFullscreen()}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    padding: '5px',
                  }}
                  className="bg-red p-5 mr-5 mt-11 text-white rounded-lg flex cursor-pointer "
                >
                  <span className="mr-2">
                    <Icon type="close" size="medium" />
                  </span>
                  <span className="mr-2">{t('fullscreen_close')}</span>
                </div>
              )}
            </div>
          )}
          <div
            className={`flex justify-center items-center ${
              isFullScreen ? 'w-full' : ''
            }`}
          >
            <OrdersStatsWidget isHubLevelViewActive={true} />
          </div>
        </div>
        <div className="w-full h-px min-h-1 bg-primary"></div>

        <div
          className={`flex flex-col flex-1 ${isFullScreen ? 'mt-4' : 'mb-4'}`}
        >
          <HubLevel hubSlug={selectedHub.slug} authClient={authClient} />
        </div>
      </div>
    </>
  );
};

export { FlowManagementHubLevel };
