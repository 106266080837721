/* eslint-disable camelcase */
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getOpsAssociates } from 'utils/network/apis';

export interface EmployeePriorityMap {
  [key: string]: number | null;
}

export const useEmployeePriorityMap = (
  hubSlug: string
): EmployeePriorityMap => {
  const { data: opsAssociates } = useQuery({
    queryKey: ['get_ops_associates', hubSlug],
    queryFn: () => getOpsAssociates(hubSlug),
    refetchInterval: 10000,
  });

  return useMemo(() => {
    return (opsAssociates?.ops_associates || []).reduce(
      (acc: EmployeePriorityMap, { employee_id, picker_priority }) => {
        if (employee_id) {
          acc[employee_id] = picker_priority;
        }
        return acc;
      },
      {}
    );
  }, [opsAssociates]);
};
