import { useEffect, useMemo, useState } from 'react';

const formatToMinutes = (seconds: number): string => {
  const h = Math.floor(seconds / 3600);
  const m = String(Math.floor((seconds % 3600) / 60));
  const s = String(Math.round(seconds % 60));

  return [h, h ? m.padStart(2, '0') : m, s.padStart(2, '0')]
    .filter(Boolean)
    .join(':');
};

const formatTime = (referenceTime: number): string => {
  const currentTime = Math.floor(Date.now() / 1000);
  let diffInSeconds = Math.abs(currentTime - referenceTime);

  const days = Math.floor(diffInSeconds / (3600 * 24));
  diffInSeconds -= days * 3600 * 24;
  const hours = Math.floor(diffInSeconds / 3600);
  diffInSeconds -= hours * 3600;
  const minutes = Math.floor(diffInSeconds / 60);
  const seconds = diffInSeconds % 60;

  let formattedTime: string = '';
  if (hours > 0) {
    formattedTime = `${String(hours).padStart(2, '0')}h${String(
      minutes
    ).padStart(2, '0')}m${String(seconds).padStart(2, '0')}s`;
  } else {
    formattedTime = `${String(minutes).padStart(2, '0')}m${String(
      seconds
    ).padStart(2, '0')}s`;
  }
  return formattedTime;
};

const useTimerEffect = (from: number): any => {
  const [diff, setDiff] = useState<number>(
    Math.floor(Date.now() / 1000 - from)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setDiff((prevDiff) => prevDiff + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setDiff(Math.floor(Date.now() / 1000 - from));
  }, [from]);

  const timeDiff = useMemo(() => diff, [diff]);

  return { timeDiff, formatTime, formatToMinutes };
};

export default useTimerEffect;

export { formatToMinutes, formatTime };
