import { Icon } from '@hubportal/components';
import TooltipSectionItem from '.';

const OATooltipSections = [
  {
    title: (
      <div className="flex text-flinkGray-light">
        <Icon type="package" />
        <span className="ml-2">Inside Hub Metrics</span>
      </div>
    ),
    answer: (
      <div>
        <div className="text-lg mt-3 font-medium">Statuses</div>
        <TooltipSectionItem
          titleKey="hub_metrics.status.picking"
          descriptionKey="learn_more.riding_metrics.status.picking.description"
        />
        <TooltipSectionItem
          titleKey="hub_metrics.status.inbounding"
          descriptionKey="learn_more.riding_metrics.status.inbounding.description"
        />
        <TooltipSectionItem
          titleKey="hub_metrics.status.inventory"
          descriptionKey="learn_more.riding_metrics.status.inventory.description"
        />
        <TooltipSectionItem
          titleKey="hub_metrics.status.restocking"
          descriptionKey="learn_more.riding_metrics.status.restocking.description"
        />
        <TooltipSectionItem
          titleKey="hub_metrics.status.hub_activities"
          descriptionKey="learn_more.riding_metrics.status.hub_activities.description"
        />
        <TooltipSectionItem
          titleKey="hub_metrics.status.idle"
          descriptionKey="learn_more.riding_metrics.status.idle.description"
        />
        <div className="w-full h-px min-h-1 bg-secondary mt-6" />
        <div className="text-lg mt-3 font-medium">Metrics</div>
        <TooltipSectionItem
          titleKey="learn_more.hub_metrics.picking_time_per_item.title"
          descriptionKey="learn_more.hub_metrics.avg_picking_time.description"
        />
        <TooltipSectionItem
          titleKey="learn_more.hub_metrics.pre_delivery_issue.title"
          descriptionKey="learn_more.hub_metrics.pre_delivery_issue.description"
        />
        <TooltipSectionItem
          titleKey="learn_more.hub_metrics.post_delivery_issue.title"
          descriptionKey="learn_more.hub_metrics.post_delivery_issue.description"
        />
        <TooltipSectionItem
          titleKey="learn_more.hub_metrics.item_skipped_rate.title"
          descriptionKey="learn_more.hub_metrics.item_skipped_rate.description"
        />
      </div>
    ),
  },
];

export default OATooltipSections;
