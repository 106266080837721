import { Tab, Tabs, TabsVariant } from '@hubportal/components';
import useStore, { ActivitySections } from 'utils/store';
import { useEffect, useMemo } from 'react';
import { EmployeeLevel } from '../employee-level';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import { Countries } from 'utils/constants';
import { Link } from 'react-router-dom';
import { useCurrentPath } from 'utils/hooks/useCurrentPath';
import OrdersStatsWidget from 'components/OrdersStats';
import FullScreen from '../hub-level/full-screen';

const EMPLOYEE_LEVEL_TAB = '/activity/employee-level';

const LiveSteering = ({ authClient }: PropsWithAuth0Client): JSX.Element => {
  const {
    country,
    selectedHub,
    activitySection,
    setActivitySection,
    isFullScreen,
  } = useStore();
  const { isAllowed } = usePermissions();
  const { t } = useTranslation();
  const { path: currentPath } = useCurrentPath();

  const isEmployeeActivityAllowed =
    country === Countries.NL || isAllowed(Permission.EMPLOYEE_ACTIVITY);

  const currentTab = useMemo(() => {
    return currentPath === EMPLOYEE_LEVEL_TAB
      ? ActivitySections.EMPLOYEE
      : ActivitySections.HUB;
  }, [currentPath]);

  useEffect(() => {
    if (currentPath === EMPLOYEE_LEVEL_TAB) {
      setActivitySection(ActivitySections.EMPLOYEE);
    } else {
      setActivitySection(ActivitySections.HUB);
    }
  }, [currentPath, setActivitySection]);

  return (
    <>
      <div className="w-screen">
        <div
          style={{ width: 'calc(100% - 150px)' }}
          className="h-36 flex justify-between mx-10"
        >
          <div
            className={`${
              isFullScreen ? 'hidden' : 'flex flex-col justify-end'
            }`}
          >
            <div className="flex flex-col justify-center header-m text-white whitespace-nowrap">
              <div
                className={`${
                  isFullScreen
                    ? 'none'
                    : 'flex text-center justify-center items-center'
                }`}
              >
                {!isFullScreen && (
                  <span className="mr-2">{selectedHub?.name}</span>
                )}
                {activitySection === ActivitySections.HUB && <FullScreen />}
              </div>
            </div>
            <Tabs
              value={currentTab}
              onChange={() => {}}
              variant={TabsVariant.secondary}
            >
              <Tab id={ActivitySections.HUB}>
                <Link reloadDocument to="/activity">
                  {t('workforce_kpi.hub_level.tab.title')}
                </Link>
              </Tab>

              {isEmployeeActivityAllowed && (
                <Tab id={ActivitySections.EMPLOYEE}>
                  <Link reloadDocument to="/activity/employee-level">
                    {t('workforce_kpi.employee.tab.title')}
                  </Link>
                </Tab>
              )}
            </Tabs>
          </div>
          <div className={`flex justify-center items-center`}>
            <OrdersStatsWidget isHubLevelViewActive={true} />
          </div>
        </div>
        <div className="w-full h-px min-h-1 bg-primary"></div>

        <div
          style={{ width: 'calc(100% - 30px)' }}
          className={`flex flex-col flex-1 ${isFullScreen ? 'mt-4' : 'mb-4'}`}
        >
          <EmployeeLevel hubSlug={selectedHub.slug} />
        </div>
      </div>
    </>
  );
};

export default LiveSteering;
