import useStore from 'utils/store';
import usePermissions from 'utils/hooks/usePermissions';

const waitPermissions = (Wrapped) => {
  return (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isAllowed } = usePermissions();
    const { permissionsReady } = useStore();

    if (!permissionsReady) {
      return <></>;
    }

    return <Wrapped {...props} />;
  };
};

export default waitPermissions;
