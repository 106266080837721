import { Icon, IconType } from '@hubportal/components';

export const Heading = (props: {
  title: string;
  icon?: string;
  children?: React.ReactNode;
  isInfoExist?: boolean;
}): JSX.Element => {
  const { title, icon, children, isInfoExist = false } = props;
  return (
    <div
      className={`flex mb-2 text-white items-center h-8 ${
        isInfoExist ? 'justify-start' : 'justify-between'
      }`}
    >
      <div className="flex font-bold text-xl">
        {icon && (
          <span className="mr-2">
            <Icon type={icon as IconType} />
          </span>
        )}
        {title}
      </div>
      {children}
    </div>
  );
};

export default Heading;
