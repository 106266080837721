import { Icon, Modal, ModalSize, ModalVariant } from '@hubportal/components';
import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type AccordionItemProps = {
  key: number;
  title: any;
  onClick: () => void;
  expanded: boolean;
  children: JSX.Element;
};

const AccordionItem: FC<AccordionItemProps> = ({
  key,
  title,
  children,
  onClick,
  expanded = false,
}) => (
  <div className="mb-4 bg-primary">
    <h2
      className={cn(
        'text-bold transition-all duration-500',
        expanded ? ['expanded', 'rounded-t'] : ['rounded']
      )}
      id={`accordion-heading-${key}`}
    >
      <button
        className="w-full pl-2 text-left font-flink-bold text-base flex justify-between p-2"
        type="button"
        aria-expanded={expanded}
        aria-controls={`accordion-${key}`}
        onClick={onClick}
      >
        <span className="ml-1">{title}</span>

        <span
          className={cn(
            'float-right mr-2',
            expanded ? 'text-white' : 'text-pink'
          )}
        >
          {expanded ? <Icon type="minus" /> : <Icon type="plus" />}
        </span>
      </button>
    </h2>
    <div
      id={`accordion-${key}`}
      className={cn(
        'overflow-hidden transition-[max-height] duration-500 rounded-b',
        expanded ? '' : 'max-h-0'
      )}
      aria-labelledby={`accordion-heading-${key}`}
    >
      <div className="px-3.5 pb-10 font-flink-light text-white bg-primary text-base">
        {children}
      </div>
    </div>
  </div>
);

export const LearnMoreModal = ({
  open,
  onClose,
  tooltipSections,
  modalTitle,
  subtitle,
}: {
  open: boolean;
  onClose: () => any;
  tooltipSections: any;
  modalTitle: string;
  subtitle?: string;
}): JSX.Element => {
  const [expandedElement, setExpanded] = useState<number | null>(0);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      const modalRef = document.querySelector(
        '.modal-container'
      ) as HTMLElement;

      if (modalRef && !modalRef.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div id="learn-more-modal">
      <Modal
        open={open}
        variant={ModalVariant.secondary}
        size={ModalSize.large}
        footer={
          <div className="w-full px-14">
            <button
              onClick={() => {
                onClose();
              }}
              className="w-full bg-primary text-white border-transparent hover:bg-pink rounded py-2 px-3"
            >
              {t('close')}
            </button>
          </div>
        }
      >
        <div className="text-left overflow-auto h-full modal-container">
          <h2 className="text-2xl font-bold">{modalTitle}</h2>
          {subtitle && <div className="mt-2 font-medium">{subtitle}</div>}
          <div className="mt-6">
            {tooltipSections.map(({ title, answer }, index) => (
              <AccordionItem
                key={index}
                title={title}
                onClick={() => {
                  setExpanded(expandedElement === index ? null : index);
                }}
                expanded={expandedElement === index}
              >
                {answer}
              </AccordionItem>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};
