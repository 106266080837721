import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getChecksRequests, getHubKpis } from 'utils/network/apis';
import { MetricCard } from './metric-card';
import { useTranslation } from 'react-i18next';
import {
  ChecksDeadlineTypes,
  ChecksSections,
  ChecksTypes,
  OAStates,
} from 'utils/constants';
import { Icon } from '@hubportal/components';
import {
  findOldestTask,
  formatNumberToPercentage,
  setChecksPayload,
  splitChecksData,
  timeToMinutesFormatter,
} from 'utils/helpers';
import { LearnMoreModal } from 'components/LearnMoreModal';
import ridingTooltipSections from 'components/TooltipSectionItem/ridingTooltipSections';
import OATooltipSections from 'components/TooltipSectionItem/OATooltipSections';
import RidersWidget from 'components/RiderWidget';
import { Workforce } from '../workforce-section';
import OpsAssociates from 'components/Ops';
import CheckProgress from 'components/CheckProgress';

type HubLevelProps = {
  hubSlug: string;
  authClient: string;
};

// eslint-disable-next-line max-lines-per-function
const HubLevelV2: React.FC<HubLevelProps> = ({
  hubSlug,
  authClient,
}: HubLevelProps) => {
  const [sectionData, setSectionData] = useState<any>();
  const [infoModal, setInfoMoreModal] = useState<string>('');

  const { t } = useTranslation();

  const { data } = useQuery({
    queryKey: ['get-hub-kpis'],
    queryFn: () => getHubKpis(hubSlug),
    refetchInterval: 10000,
  });

  const { data: checkData } = useQuery({
    queryKey: ['checks'],
    queryFn: () => getChecksRequests(hubSlug),
    refetchInterval: 30000,
  });

  useEffect(() => {
    if (!checkData?.hub_checks) {
      return;
    }
    const { stockCheckP0, freshChecksRoutine, bbdChecksRoutine, stockCheckP1 } =
      splitChecksData(checkData?.hub_checks);

    setSectionData({
      stockCheckP0: stockCheckP0 || [],
      freshChecksRoutine: freshChecksRoutine || [],
      bbdChecksRoutine: bbdChecksRoutine || [],
      stockCheckP1: stockCheckP1 || [],
    });
  }, [checkData?.hub_checks]);

  const urgentSection = useMemo(() => {
    const stockCheckP0 = sectionData?.stockCheckP0;
    if (stockCheckP0?.length === 0 || !stockCheckP0) {
      return [];
    }

    const oldestTask = findOldestTask(stockCheckP0);

    const urgentStockChecks: Check = {
      remaining_checks_count: stockCheckP0?.length,
      section: ChecksSections.URGENT,
      type: ChecksTypes.STOCK_CHECK_P0,
      deadline_type: ChecksDeadlineTypes.SLA,
      deadline_at: oldestTask!.deadline_at,
      oldest_task_created_date: oldestTask.created_at,
    };

    return [urgentStockChecks];
  }, [sectionData?.stockCheckP0]);

  const freshChecksSection: Check = useMemo(() => {
    const freshChecksRoutine = sectionData?.freshChecksRoutine;
    if (!freshChecksRoutine) {
      return setChecksPayload(ChecksTypes.FRESH_CHECK, null);
    } else {
      return setChecksPayload(ChecksTypes.FRESH_CHECK, freshChecksRoutine);
    }
  }, [sectionData?.freshChecksRoutine]);

  const bbdChecksSection: Check = useMemo(() => {
    const bbdChecksRoutine = sectionData?.bbdChecksRoutine;
    if (!bbdChecksRoutine) {
      return setChecksPayload(ChecksTypes.BBD_CHECK, null);
    } else {
      return setChecksPayload(ChecksTypes.BBD_CHECK, bbdChecksRoutine);
    }
  }, [sectionData?.bbdChecksRoutine]);

  const stockChecksP1: Check = useMemo(() => {
    const stockCheckP1 = sectionData?.stockCheckP1;
    if (!stockCheckP1) {
      return setChecksPayload(ChecksTypes.STOCK_CHECK_P1, null);
    } else {
      return setChecksPayload(ChecksTypes.STOCK_CHECK_P1, stockCheckP1);
    }
  }, [sectionData?.stockCheckP1]);

  const groupOpsAssociatesByStatus = (opsAssociates: Workforce[]): any => {
    const grouped: { [key: string]: Workforce[] } = {
      picking: [],
      inbounding: [],
      inventory: [],
      restocking: [],
      hub_activities: [],
      idle: [],
    };

    opsAssociates.forEach((opsAssociate) => {
      switch (opsAssociate.activity) {
        case OAStates.PICKING:
          grouped.picking.push(opsAssociate);
          break;
        case OAStates.INBOUNDING:
          grouped.inbounding.push(opsAssociate);
          break;
        case OAStates.INVENTORY:
          grouped.inventory.push(opsAssociate);
          break;
        case OAStates.RESTOCKING:
          grouped.restocking.push(opsAssociate);
          break;
        case OAStates.HUB_ACTIVITIES:
          grouped.hub_activities.push(opsAssociate);
          break;
        case OAStates.IDLE:
          grouped.idle.push(opsAssociate);
          break;
        default:
          break;
      }
    });

    return {
      idle: grouped.idle,
      inbounding: grouped.inbounding,
      restocking: grouped.restocking,
      picking: grouped.picking,
      inventory: grouped.inventory,
      hub_activities: grouped.hub_activities,
    };
  };

  const groupedOpsAssociates = groupOpsAssociatesByStatus(
    data?.inside_hub?.ops_associates ?? []
  );

  const ridingKpi = {
    mainMetric: {
      title: t('employee_metrics.metric.deliveries_per_hour'),
      value: data?.riding?.deliveries_per_hour?.value ?? 0,
      link: 'employee-level?kpi=riding&metric=deliveries_per_hour&sort_by=ASC',
    },
    metrics: [
      {
        title: t('employee_metrics.metric.acceptance_rate'),
        value: formatNumberToPercentage(
          data?.riding?.acceptance_rate?.value ?? 0
        ),
        link: 'employee-level?kpi=riding&metric=acceptance_rate&sort_by=ASC',
      },
      {
        title: t('employee_metrics.metric.avg_time_at_customer_in_seconds'),
        value: timeToMinutesFormatter(
          data?.riding?.avg_time_at_customer_in_seconds?.value ?? 0
        ),
        link: 'employee-level?kpi=riding&metric=avg_time_at_customer_in_seconds&sort_by=DESC',
      },
      {
        title: t(
          'employee_metrics.metric.avg_rider_preparation_time_in_seconds'
        ),
        value: timeToMinutesFormatter(
          data?.riding?.avg_rider_preparation_time_in_seconds?.value ?? 0
        ),
        link: 'employee-level?kpi=riding&metric=avg_rider_preparation_time_in_seconds&sort_by=DESC',
      },
      {
        title: t('employee_metrics.metric.unaccounted_rider_time_offline'),
        value: formatNumberToPercentage(
          data?.riding?.unaccounted_time_offline_rate?.value ?? 0
        ),
        link: 'employee-level?kpi=riding&metric=unaccounted_time_offline&sort_by=DESC',
      },
      {
        title: t('employee_metrics.metric.trip_on_time_compliance'),
        value: formatNumberToPercentage(
          data?.riding?.trip_on_time_compliance?.value ?? 0
        ),
        link: 'employee-level?kpi=riding&metric=trip_on_time_compliance&sort_by=ASC',
      },
    ],
  };

  const insideHubKpi = {
    mainMetric: {
      title: t('employee_metrics.metric.picking_time_per_item_in_seconds'),
      value: timeToMinutesFormatter(
        data?.inside_hub?.picking_time_per_item_in_seconds?.value ?? 0
      ),
      link: 'employee-level?kpi=inside-hub&metric=avg_picking_speed_per_item_in_seconds&sort_by=DESC',
    },
    metrics: [
      {
        title: t('employee_metrics.metric.pre_delivery_issue_rate'),
        value: formatNumberToPercentage(
          data?.inside_hub?.pre_delivery_issue_rate?.value ?? 0
        ),
        link: 'employee-level?kpi=inside-hub&metric=pre_delivery_issue_rate&sort_by=DESC',
      },
      {
        title: t('employee_metrics.metric.item_skipped_rate'),
        value: formatNumberToPercentage(
          data?.inside_hub?.item_skipped_rate?.value ?? 0
        ),
        link: '',
      },
      {
        title: t('employee_metrics.metric.post_delivery_issue_rate'),
        value: formatNumberToPercentage(
          data?.inside_hub?.post_delivery_issue_rate?.value ?? 0
        ),
        link: 'employee-level?kpi=inside-hub&metric=post_delivery_issue_rate&sort_by=DESC',
      },
    ],
  };

  return (
    <div className="pb-10 mx-10 cursor-default h-full mt-6">
      <div className="grow flex flex-nowrap items-center gap-6 justify-center flex-row h-full">
        <div className="basis-1 grow flex h-full">
          <div className="flex-1 flex-grow h-full">
            <div className="bg-primary rounded-r-lg rounded-l-lg  max-h-max  grow flex flex-nowrap flex-row h-full overflow-y-hidden">
              <div className="basis-1 grow border-secondary border-r-[1px]">
                {infoModal === 'oa' && (
                  <LearnMoreModal
                    open={infoModal === 'oa'}
                    onClose={() => setInfoMoreModal('')}
                    tooltipSections={OATooltipSections}
                    modalTitle={t('learn_more.oa.title')}
                  />
                )}
                <div className="flex flex-col h-full">
                  <MetricCard
                    mainMetric={insideHubKpi.mainMetric}
                    otherMetrics={insideHubKpi.metrics}
                    setInfoMoreModal={() => setInfoMoreModal('oa')}
                    title={t(`hub_level.metric_card.oa.title`)}
                  />
                  <div className="p-4 font-normal">
                    <h3>{t('ops.inventory.title')}</h3>
                    <div className="grid grid-cols-2">
                      <div
                        className={`flex flex-col p-2 ${
                          (urgentSection[0]?.remaining_checks_count ?? 0) > 0
                            ? ''
                            : 'opacity-50'
                        }`}
                      >
                        <span className="text-white">
                          {urgentSection[0]?.remaining_checks_count ?? 0}
                        </span>
                        <span>{t('checks.urgent.title')}</span>
                      </div>
                      <div
                        className={`flex flex-col p-2 ${
                          (checkData?.restocking_count ?? 0) > 0
                            ? ''
                            : 'opacity-50'
                        }`}
                      >
                        <span className="text-white">
                          {checkData?.restocking_count ?? 0} items
                        </span>
                        <span>{t('checks.restocking.title')}</span>
                      </div>
                    </div>
                    {stockChecksP1?.completed_percentage === 100 &&
                    freshChecksSection?.completed_checks_count === 100 &&
                    bbdChecksSection?.completed_percentage === 100 ? (
                      <div className="flex flex-row items-center leading-4 mt-2 p-4 bg-secondary rounded-lg">
                        {t('all_checks_are_completed')}
                        <span className="text-[#008040] ml-2">
                          <Icon type="verification" />
                        </span>
                      </div>
                    ) : (
                      <div className="grid grid-cols-3 leading-4 mt-2">
                        <CheckProgress
                          title={t(`checks.fresh_check`)}
                          completedChecksCount={
                            freshChecksSection?.completed_checks_count ?? 0
                          }
                          allChecksCount={
                            freshChecksSection?.all_checks_count ?? 0
                          }
                          completedPercentage={
                            freshChecksSection.completed_percentage ?? 0
                          }
                          progressColor="bg-[#E3F3CF]"
                        />
                        <CheckProgress
                          title={t(`checks.bbd_check`)}
                          completedChecksCount={
                            bbdChecksSection?.completed_checks_count ?? 0
                          }
                          allChecksCount={
                            bbdChecksSection?.all_checks_count ?? 0
                          }
                          completedPercentage={
                            bbdChecksSection.completed_percentage ?? 0
                          }
                          progressColor="bg-[#E3F3CF]"
                        />
                        <CheckProgress
                          title={t(`checks.stock_check_p1`)}
                          completedChecksCount={
                            stockChecksP1?.completed_checks_count ?? 0
                          }
                          allChecksCount={stockChecksP1?.all_checks_count ?? 0}
                          completedPercentage={
                            stockChecksP1.completed_percentage ?? 0
                          }
                          progressColor="bg-[#E3F3CF]"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="basis-1 grow overflow-y-scroll">
                <div className="basis-1 grow overflow-y-auto p-4">
                  <OpsAssociates data={groupedOpsAssociates} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="basis-1 grow flex h-full">
          <div className="flex-1 flex-grow h-full">
            <div className="bg-primary rounded-r-lg rounded-l-lg  max-h-max  grow flex flex-nowrap flex-row h-full overflow-y-hidden">
              <div className="basis-1 grow border-secondary border-r-[1px]">
                {infoModal === 'riding' && (
                  <LearnMoreModal
                    open={infoModal === 'riding'}
                    onClose={() => setInfoMoreModal('')}
                    tooltipSections={ridingTooltipSections}
                    modalTitle={t('learn_more.riding.title')}
                  />
                )}
                <MetricCard
                  mainMetric={ridingKpi.mainMetric}
                  otherMetrics={ridingKpi.metrics}
                  hasRider={true}
                  setInfoMoreModal={() => setInfoMoreModal('riding')}
                  title={t('hub_level.metric_card.riding.title')}
                />
              </div>
              <div className="basis-1 grow overflow-y-scroll">
                <div className="basis-1 grow overflow-y-auto">
                  <RidersWidget authClient={authClient} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HubLevelV2;
