import { Icon } from '@hubportal/components';
import useTimerEffect from 'src/hooks/useTimerEffect';

import React from 'react';
import { useTranslation } from 'react-i18next';

const Heading = (props: {
  title: string;
  children?: React.ReactNode;
}): JSX.Element => {
  const { title, children } = props;
  return (
    <div className="flex justify-between">
      <div className="font-medium text-sm">{title}</div>
      {children}
    </div>
  );
};

const Card = (props: { children; className? }): JSX.Element => {
  const { children, className } = props;
  return (
    <div
      className={`bg-[#23272A] rounded-xl pb-2 ${className}`}
      data-testid="card"
    >
      {children}
    </div>
  );
};

const CardItem = (props: {
  icon?: JSX.Element;
  item: string;
  value: string;
  isPickingSpeedEnabled: boolean;
}): JSX.Element => {
  const { icon, item, value, isPickingSpeedEnabled } = props;
  const { t } = useTranslation();

  return (
    <div className="flex title-s text-white bg-secondary px-4 py-2 rounded-t-lg items-center justify-between">
      <div className="flex items-center font-medium gap-2">
        {icon}
        {item}
      </div>
      {isPickingSpeedEnabled && (
        <div className="text-xs justify-end text-right flex grow">
          {t('ops.picking_speed.title')}
        </div>
      )}

      <div className="font-bold text-xl justify-end flex w-12">{value}</div>
    </div>
  );
};

const CardRow = (props: { children }): JSX.Element => {
  const { children } = props;

  return (
    <div className="flex flex-col bg-secondary px-2 py-2 rounded-b-lg mt-[1px]">
      {children}
    </div>
  );
};

const CardRowItem = (props: {
  item: string;
  value: string;
  pickingSpeed: number;
  isPickingSpeedEnabled: boolean;
}): JSX.Element => {
  const { item, value, pickingSpeed, isPickingSpeedEnabled } = props;

  const { timeDiff, formatToMinutes } = useTimerEffect(
    new Date(value).getTime() / 1000
  );

  return (
    <div className={`flex items-center p-2 justify-between`}>
      <div className="text-s gap-2 flex">{item}</div>
      {pickingSpeed > 0 && isPickingSpeedEnabled && (
        <div className="flex grow text-sm justify-end">
          {pickingSpeed}s/item
        </div>
      )}
      <div className="detail-l text-white items-center justify-end flex w-12">
        <div className="whitespace-nowrap">{formatToMinutes(timeDiff)}</div>
      </div>
    </div>
  );
};

const CardEmptyItem = (props: { text: string }): JSX.Element => {
  const { text } = props;
  return (
    <div className="flex py-2 px-2">
      <div className="detail-l">{text}</div>
    </div>
  );
};

const StatusDot = (props: { color?: string }): JSX.Element => {
  const { color = '#ABABAB' } = props;
  return (
    <div
      className={`w-2 h-2 rounded-full mr-2`}
      style={{
        backgroundColor: color,
      }}
      data-testid="status-dot"
    />
  );
};

const CardSkeleton = (): JSX.Element => {
  return (
    <div data-testid="card-skeleton" className="h-full">
      <div className="mb-2 bg-primary h-4 w-20 rounded-full animate-pulse"></div>
      <Card className="relative h-full animate-pulse"> </Card>
    </div>
  );
};

const CardError = (props: { onClick? }): JSX.Element => {
  const { t } = useTranslation();
  const { onClick } = props;
  return (
    <Card className="flex justify-center items-center h-full flex-col">
      <div className="flex items-center">
        <Icon type="caution" />
        <span className="title-xs ml-2">{t('network_error')}</span>
      </div>
      {onClick && (
        <button
          className="btn btn-primary mt-2 underline text-xs"
          onClick={onClick}
          data-testid="retry"
        >
          {t('retry')}
        </button>
      )}
    </Card>
  );
};

const MemoizedCardRowItem = React.memo(CardRowItem);

export {
  Card,
  Heading,
  CardItem,
  CardRow,
  MemoizedCardRowItem as CardRowItem,
  CardEmptyItem,
  StatusDot,
  CardSkeleton,
  CardError,
};
