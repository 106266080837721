import { useRef, useState } from 'react';
import { Icon, Tooltip, TooltipVariant } from '@hubportal/components';

export const TooltipHeader = ({
  label,
  info,
  children,
}: {
  label: string;
  info: string;
  children?: JSX.Element;
}): JSX.Element => {
  const ref = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div className="flex items-center gap-1">
      <div
        ref={ref}
        onMouseOver={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        {children || <Icon type="caution" size="medium" data-testid="icon" />}
      </div>
      {label}
      <Tooltip
        refElement={ref?.current || null}
        visible={tooltipVisible}
        variant={TooltipVariant.pink}
        placement="bottom"
      >
        <div className="w-[140px] text-center">{info}</div>
      </Tooltip>
    </div>
  );
};
