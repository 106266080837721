import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type TooltipSectionItemProps = {
  titleKey: string;
  descriptionKey: string;
};

const TooltipSectionItem: FC<TooltipSectionItemProps> = ({
  titleKey,
  descriptionKey,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt-6">
      <div className="font-medium mb-1">{t(titleKey)}</div>
      <div className="font-light text-sm">{t(descriptionKey)}</div>
    </div>
  );
};

export default TooltipSectionItem;
