import { Outlet } from 'react-router-dom';

const Main = (): any => {
  return (
    <div style={{ width: '100%' }} className="flex flex-col h-screen ">
      <div className="flex bg-secondary text-gray-300 flex-col flex-1">
        <Outlet />
      </div>
    </div>
  );
};

export { Main };
