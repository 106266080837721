import { useState, useEffect } from 'react';
import { eppo } from 'utils/eppo';

export const useFeatureFlag = (flags, attributes) => {
  const [decisions, setDecisions] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await eppo(flags, attributes);
        setDecisions(res?.data ?? {});
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { decisions, loading };;
};
