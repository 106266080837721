import { Auth0Client, User } from '@auth0/auth0-spa-js';
import sdk from '@hubportal/sdk';

export const authClient = new Auth0Client({
  redirect_uri: `${location.origin}/workforce/validate`,
  domain:
    process.env.REACT_APP_AUTH0_BASE_URL?.replace(/^https?:\/\//, '') || '',
  client_id: process.env.REACT_APP_AUTH0_WORKFORCE_APP_CLIENT_ID || '',
  useRefreshTokens: false,
  max_age: 7200,
});

export const isAuthenticated = async (): Promise<boolean> => {
  try {
    await authClient.checkSession({ ignoreCache: true });
    const authenticated = await authClient.isAuthenticated();
    return authenticated;
  } catch (err) {
    return false;
  }
};

export const getUser = async (): Promise<User | undefined> => {
  return sdk.getAuthClient()?.getUser();
};
