import { Icon } from '@hubportal/components';
import useTimerEffect from 'src/hooks/useTimerEffect';

import React from 'react';
import { useTranslation } from 'react-i18next';

const Heading = (props: {
  title: string;
  children?: React.ReactNode;
}): JSX.Element => {
  const { title, children } = props;
  return (
    <div className="flex justify-between">
      <div className="font-medium text-sm">{title}</div>
      {children}
    </div>
  );
};

const Card = (props: { children; className? }): JSX.Element => {
  const { children } = props;
  return (
    <div className="mb-2" data-testid="card">
      {children}
    </div>
  );
};

const CardItem = (props: {
  icon?: JSX.Element;
  item: string;
  value: string;
}): JSX.Element => {
  const { icon, item, value } = props;

  return (
    <div
      className={`flex title-m  items-center justify-between border-secondary  py-2 ${
        value ? 'border-b-[1px] text-white' : 'border-none text-gray-600'
      }`}
    >
      <div className="flex items-center font-medium gap-2">
        {icon}
        {item}
      </div>
      <div className="font-bold text-xl justify-end flex w-12">{value}</div>
    </div>
  );
};

const CardRow = (props: { children }): JSX.Element => {
  const { children } = props;

  return (
    <>{children && <div className="flex flex-col py-2">{children}</div>}</>
  );
};

const CardRowItem = (props: {
  item: string;
  value: string;
  pickingSpeed: number;
}): JSX.Element => {
  const { t } = useTranslation();
  const { item, value, pickingSpeed } = props;

  const { timeDiff, formatToMinutes } = useTimerEffect(
    new Date(value).getTime() / 1000
  );

  return (
    <div className={`flex items-center p-2 justify-between`}>
      <div>
        <div className="text-s gap-2 flex text-white">{item}</div>
        {pickingSpeed > 0 && (
          <div className="flex grow text-xs text-[#BFBFBF]">
            {t('ops.picking_speed.title')}: {pickingSpeed ?? 0}s/item
          </div>
        )}
      </div>
      <div className="detail-l text-[#BFBFBF] items-center justify-end flex w-12">
        <div className="whitespace-nowrap">{formatToMinutes(timeDiff)}</div>
      </div>
    </div>
  );
};

const CardEmptyItem = (props: { text: string }): JSX.Element => {
  const { text } = props;
  return (
    <div className="flex py-2 px-2">
      {/* <div className="detail-l">{text}</div> */}
    </div>
  );
};

const StatusDot = (props: { color?: string }): JSX.Element => {
  const { color = '#ABABAB' } = props;
  return (
    <div
      className={`w-2 h-2 rounded-full mr-2`}
      style={{
        backgroundColor: color,
      }}
      data-testid="status-dot"
    />
  );
};

const CardSkeleton = (): JSX.Element => {
  return (
    <div data-testid="card-skeleton" className="h-full">
      <div className="mb-2 h-4 w-20 rounded-full animate-pulse"></div>
      <Card className="relative h-full animate-pulse"> </Card>
    </div>
  );
};

const CardError = (props: { onClick? }): JSX.Element => {
  const { t } = useTranslation();
  const { onClick } = props;
  return (
    <Card className="flex justify-center items-center h-full flex-col">
      <div className="flex items-center">
        <Icon type="caution" />
        <span className="title-xs ml-2">{t('network_error')}</span>
      </div>
      {onClick && (
        <button
          className="btn btn-primary mt-2 underline text-xs"
          onClick={onClick}
          data-testid="retry"
        >
          {t('retry')}
        </button>
      )}
    </Card>
  );
};

const MemoizedCardRowItem = React.memo(CardRowItem);

export {
  Card,
  Heading,
  CardItem,
  CardRow,
  MemoizedCardRowItem as CardRowItem,
  CardEmptyItem,
  StatusDot,
  CardSkeleton,
  CardError,
};
