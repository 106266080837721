/**
 *
 * TODO:
 * This file contains all the apis for now but it should be splitted by domain in future
 * Responses should have proper types
 *
 *  */

import { fetch, Origin } from '..';

export const getChecksRequests = async (hubSlug): Promise<any> => {
  return fetch('GET', 'CHECKS_REQUESTS', { interpolate: { hubSlug } });
};

export const getStuffStates = async (hubSlug): Promise<any> => {
  return fetch('GET', 'STUFF_STATES', { interpolate: { hubSlug } });
};

export const getOpsStatesV2 = async (hubSlug): Promise<any> => {
  return fetch('GET', 'OPS_STATES_V2', { interpolate: { hubSlug } });
};

export const getWorkforceKpi = async (hubSlug, data): Promise<any> => {
  return fetch('GET', 'WORKFORCE_KPI', { data, interpolate: { hubSlug } });
};

export const getHubKpis = async (hubSlug): Promise<any> => {
  return fetch('GET', 'HUB_KPIS', { interpolate: { hubSlug } });
};

export const getOpsAssociates = async (hubSlug): Promise<any> => {
  return fetch(
    'GET',
    'OPS_ASSOCIATES',
    {
      interpolate: { hubSlug },
    },
    Origin.OpsStateManager
  );
};

export const updatePickerPriority = async (
  hubSlug: string,
  employeeId: string,
  pickingPriority: number
): Promise<any> => {
  return fetch(
    'PUT',
    'OPS_ASSOCIATES_UPDATE_PICKER_PRIORITY',
    {
      interpolate: { hubSlug, employeeId },
      data: { priority: pickingPriority },
    },
    Origin.OpsStateManager
  );
};
