import { CardError } from 'components/Card';
import { useState } from 'react';
import Parcel from 'single-spa-react/parcel';

const ridersWidgetUrl: string = process.env.REACT_RIDERS_WIDGET_URL || '';

const RidersWidget = ({ authClient }: PropsWithAuth0Client): JSX.Element => {
  const [error, setError] = useState(false);

  if (error || !ridersWidgetUrl) {
    return (
      <div className="" data-testid="card-error">
        <CardError />
      </div>
    );
  }

  return (
    <Parcel
      wrapClassName=""
      authClient={authClient}
      isLiveSteering={true}
      handleError={() => {
        setError(true);
      }}
      config={async () => {
        return System.import(ridersWidgetUrl);
      }}
      mapEnabled={false}
      titleEnabled={false}
    />
  );
};

export default RidersWidget;
