import { Icon } from '@hubportal/components';
import WorkforceSection, { GroupedWorforce } from '../workforce-section';
import useStore from 'utils/store';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Metric {
  title: string;
  value: number | string;
  link: string;
}

interface MetricCardProps {
  workforceList: GroupedWorforce[];
  mainMetric: Metric;
  otherMetrics: Metric[];
  isInventoryWidgetActive?: boolean;
  urgentCheckCount?: number;
  setIsInventoryWidgetActive?: (value: boolean) => void;
  hasRider?: boolean;
}

export function MetricCard({
  workforceList,
  mainMetric,
  otherMetrics,
  isInventoryWidgetActive = false,
  urgentCheckCount,
  hasRider,
  setIsInventoryWidgetActive,
}: MetricCardProps): JSX.Element {
  const { isFullScreen } = useStore();
  const { t } = useTranslation();

  return (
    <div className="h-full">
      <div
        className={`bg-primary rounded-lg flex flex-col justify-between ${
          isFullScreen ? 'h-[75vh]' : 'h-[65vh] lg:h-[73vh]'
        }`}
      >
        <div className="border-b-[1px] border-secondary grid grid-flow-col">
          <WorkforceSection workforceList={workforceList} hasRider={hasRider} />
        </div>
        <div className="pb-8 px-6 justify-center text-white">
          <Link
            to={mainMetric.link}
            reloadDocument
            className="flex justify-center flex-col items-center p-2 lg:p-3 xl:p-4"
          >
            <div className="text-4xl font-bold ">{mainMetric.value}</div>
            <div className="text-xl mt-4">{mainMetric.title}</div>
          </Link>

          <div
            className={`grid grid-flow-col mt-0 xl:mt-4 gap-x-2 gap-y-2 xl:gap-y-4 xl:gap-x-8  ${
              !isInventoryWidgetActive && setIsInventoryWidgetActive
                ? 'grid-rows-1 xl:grid-rows-2'
                : 'grid-rows-3'
            }`}
          >
            {otherMetrics.map((metric, i) => {
              const MetricContent = (
                <div className="md:p-2 lg:p-3 xl:p-4">
                  <div className="text-2xl font-bold">{metric.value}</div>
                  <div className="mt-1.5 text-base">{metric.title}</div>
                </div>
              );

              return metric.link ? (
                <Link key={i} to={metric.link} reloadDocument>
                  {MetricContent}
                </Link>
              ) : (
                <div key={i}>{MetricContent}</div>
              );
            })}
          </div>
        </div>
        <div
          className={`${hasRider || isInventoryWidgetActive ? 'h-[73px]' : ''}`}
        >
          {!isInventoryWidgetActive && setIsInventoryWidgetActive && (
            <div className="p-4 border-t-[1px] border-secondary text-white flex justify-between items-center pt-6">
              <div className="ml-2">
                <span className="text-2xl font-bold mr-2">
                  {urgentCheckCount}
                </span>
                <span className="text-base font-light">
                  {t('checks.urgent.title')}
                </span>
              </div>
              <button
                className="bg-secondary py-2 px-3 rounded-md text-base flex"
                onClick={() =>
                  setIsInventoryWidgetActive && setIsInventoryWidgetActive(true)
                }
              >
                <span>{t('hub_metrics.show_inventory')}</span>{' '}
                <Icon type="arrowRightDouble" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
