import { memo, useState } from 'react';
import Parcel from 'single-spa-react/parcel';
import useStore from 'utils/store';

const ordersStatsWidgetUrl = process.env.REACT_ORDERS_STATS_WIDGET_URL || '';

type OrdersStatsWidgetProps = {
  isHubLevelViewActive: boolean;
};

const OrdersStatsWidget: React.FC<OrdersStatsWidgetProps> = ({
  isHubLevelViewActive,
}: OrdersStatsWidgetProps) => {
  const [error, setError] = useState(false);
  const { isFullScreen } = useStore();

  if (error || !ordersStatsWidgetUrl) {
    return <div></div>;
  }

  return (
    <div
      className={`${
        isFullScreen ? 'w-full mt-6' : 'w-[600px] lg:w-[690px] xl:w-[878px]'
      }`}
    >
      <Parcel
        wrapClassName={`${
          isFullScreen ? 'w-full h-full' : 'w-[600px] lg:w-[690px] xl:w-[878px]'
        }`}
        isHubLevelViewActive={isHubLevelViewActive}
        handleError={(err) => {
          setError(true);
        }}
        config={async () => {
          return System.import(ordersStatsWidgetUrl);
        }}
      />
    </div>
  );
};

export default memo(OrdersStatsWidget);
