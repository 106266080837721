import React, { useEffect, useState } from 'react';
import { authClient, getUser, isAuthenticated } from 'utils/auth';
import { PopupCancelledError, PopupTimeoutError } from '@auth0/auth0-spa-js';
import useStore from 'utils/store';
import { Countries } from 'utils/constants';
import { useTranslation } from 'react-i18next';

enum Provider {
  AUTH0 = 'Username-Password-Authentication',
  GOOGLE = 'core-ops-google-social-connection',
}

export const decideAuth0ConnectionType = (sub: string): Provider => {
  const [provider] = sub.split('|');
  return provider === 'auth0' ? Provider.AUTH0 : Provider.GOOGLE;
};

const withMFA = (Wrapped) => {
  return (props) => {
    const { t } = useTranslation();

    const [ready, setReady] = useState(false);
    const [showPopupBlockedMessage, setShowPopupBlockedMessage] =
      useState(false);
    const { country } = useStore();

    const onLoad = async (): Promise<void> => {
      try {
        const authenticated = await isAuthenticated();
        if (!authenticated) {
          const user = await getUser();
          const provider = decideAuth0ConnectionType(user?.sub || '');

          try {
            await authClient.getTokenWithPopup({
              connection: provider,
              prompt: 'select_account',
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              scope: `read:employee_activity:all`,
              max_age: 7200,
            });

            setReady(true);
          } catch (popupError: any) {
            if (
              popupError &&
              popupError.toString().includes('Unable to open a popup')
            ) {
              setShowPopupBlockedMessage(true);
            } else if (popupError instanceof PopupTimeoutError) {
              popupError.popup.close();
            }
          }
        } else {
          setReady(true);
        }
      } catch (err: any) {
        setShowPopupBlockedMessage(false);
        throw err;
      }
    };

    const handleContinue = (): void => {
      setShowPopupBlockedMessage(false);
      onLoad();
    };

    useEffect(() => {
      if (country === Countries.NL || process.env.REACT_APP_ENV === 'staging') {
        setReady(true);
      } else {
        onLoad();
      }
    }, [country]);

    return ready ? (
      <Wrapped {...props} />
    ) : (
      <>
        <div className="flex flex-col items-center justify-center mt-8">
          {showPopupBlockedMessage ? (
            <>
              <p className="text-center mb-4">
                {t('2fa.popup.disable.message')}
              </p>
              <p className="text-center mb-4">
                {t('2fa.popup.enable.message')}
              </p>
            </>
          ) : (
            <p className="text-center mb-4">{t('2fa.popup.error.message')}</p>
          )}
          <button
            onClick={() => handleContinue()}
            className="px-4 py-2 bg-pink text-white rounded"
          >
            {t('2fa.popup.continue.message')}
          </button>
        </div>
      </>
    );
  };
};

const WithMFAWrapperFC = ({
  children,
}: React.PropsWithChildren): JSX.Element => {
  return <>{children}</>;
};

export const WithMFAWrapper = withMFA(WithMFAWrapperFC);

export default withMFA;
