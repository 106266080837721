import React from 'react';
import useTimerEffect from 'src/hooks/useTimerEffect';

const CalculatedTime = (props: { inStateSince: string }): JSX.Element => {
  const { inStateSince } = props;
  const { formatTime } = useTimerEffect(
    Math.floor(new Date(inStateSince ?? 0).getTime() / 1000)
  );

  let unixTime: number = 0;
  unixTime = Math.floor(new Date(inStateSince ?? 0).getTime() / 1000);

  return formatTime(unixTime);
};

const MemoizedCardRowItem = React.memo(CalculatedTime);

export { MemoizedCardRowItem as CalculatedTimeSince };
