import { useEffect, useSyncExternalStore } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import useStore from 'utils/store';
import sdk from '@hubportal/sdk';

import { config } from './i18n';
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FEATURE_FLAGS } from 'utils/eppo/flags';
import { useFeatureFlag } from 'utils/hooks/useFeatureFlag';

i18n.use(initReactI18next).init(config);

export default function Root({
  authClient,
}: PropsWithAuth0Client): JSX.Element {
  const { setCountry, setSelectedHub, setFeatureFlagDecisions } = useStore();

  const hub = useSyncExternalStore(sdk.onHubChange, () => sdk.getHub());
  const locale = useSyncExternalStore(sdk.onLocaleChange, () =>
    sdk.getLocale()
  );

  const country = useSyncExternalStore(sdk.onCountryChange, () =>
    sdk.getCountry()
  );

  useEffect(() => {
    setSelectedHub(hub);
  }, [hub]);

  useEffect(() => {
    locale && typeof locale === 'string' && i18n.changeLanguage(locale);
  }, [locale]);

  useEffect(() => {
    setCountry(country);
  }, [country]);

  const queryClient = new QueryClient();
  const { decisions, loading } = useFeatureFlag(
    [FEATURE_FLAGS.OF_POOLS_WIDGET],
    { hub_slug: hub.slug }
  );

  useEffect(() => {
    if (!loading) {
      setFeatureFlagDecisions(decisions);
    }
  }, [loading, decisions]);

  return (
    <QueryClientProvider client={queryClient}>
      <App authClient={authClient} />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}
