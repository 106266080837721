import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Icon } from '@hubportal/components';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardItem,
  CardRow,
  CardRowItem,
  CardEmptyItem,
  CardSkeleton,
  CardError,
} from 'components/Card';
import { getOpsStatesV2 } from 'utils/network/apis';
import useStore from 'utils/store';

type OpsAssociateFromApi = {
  avg_picking_speed_per_item_in_seconds: number;
  employee_number: string;
  in_state_since: string;
  state: string;
  first_name: string;
  short_name: string;
};

export default function OpsAssociatesV2(props: { hub: string }): JSX.Element {
  const { hub } = props;
  const { t } = useTranslation();
  const { isFullScreen } = useStore();

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ['ops-states-v2'],
    queryFn: () => getOpsStatesV2(hub),
    refetchInterval: 10000,
  });

  const groupedAndFilteredOpsAssociates: Record<string, OpsAssociateFromApi[]> =
    useMemo(() => {
      if (!data) {
        return {};
      }

      const result: Record<string, OpsAssociateFromApi[]> = {};

      data.hub_ops_associates.forEach((opsAssociate: OpsAssociateFromApi) => {
        result[opsAssociate.state] = (result[opsAssociate.state] || []).concat(
          opsAssociate
        );
      });
      return result;
    }, [data]);

  const cards = useMemo(() => {
    if (!data) {
      return [];
    }

    return [
      {
        icon: 'picking',
        title: t('ops.picking.title'),
        emptyText: t('ops.picking.inactive'),
        data: groupedAndFilteredOpsAssociates.picking || [],
      },
      {
        icon: 'idle',
        title: 'Ready to pick',
        emptyText: t('ops.idle.inactive'),
        data: groupedAndFilteredOpsAssociates.ready_to_pick || [],
      },
    ];
  }, [data]);

  if (isLoading) {
    return (
      <div className="w-[300px] h-[700px]">
        <CardSkeleton />
      </div>
    );
  }

  if (error) {
    const onClick = (): Promise<any> => refetch();

    return (
      <div className="w-[300px] h-[700px]">
        <CardError onClick={onClick} />
      </div>
    );
  }

  return (
    <div
      className={`overflow-y-auto p-4 grow bg-primary rounded-lg ${
        isFullScreen ? 'h-[75vh]' : 'h-[65vh] lg:h-[73vh]'
      }`}
    >
      {cards.map((card, i) => {
        return (
          <Card key={i}>
            <CardItem
              icon={<Icon type={card.icon as 'picking' | 'idle'} />}
              item={card.title}
              value={card.data.length.toString()}
              isPickingSpeedEnabled={false}
            />
            <CardRow>
              {card.data.map((item) => {
                return (
                  <CardRowItem
                    key={item.employee_number}
                    item={`${item.short_name ?? item.employee_number}`}
                    value={item.in_state_since}
                    pickingSpeed={item.avg_picking_speed_per_item_in_seconds}
                    isPickingSpeedEnabled={false}
                  />
                );
              })}
              {!card.data.length && <CardEmptyItem text={card.emptyText} />}
            </CardRow>
          </Card>
        );
      })}
    </div>
  );
}
