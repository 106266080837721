import type { Auth0Client } from '@auth0/auth0-spa-js';
import { AxiosRequestConfig } from 'axios';

export const tokenMiddleware =
  (authClient: Auth0Client) =>
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    try {
      const token = await authClient.getTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: `${window.location.origin}/validate`,
      });
      (config.headers as any).Authorization = `Bearer ${token}`;
      return config;
    } catch (err) {
      if ((err as any).error === 'login_required') {
        // history.go(0);
      }
      return config;
    }
  };
