import withMFA from 'utils/hoc/withMFA';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Icon,
  Select,
  SelectItem,
  SelectVariant,
  TableSortBy,
} from '@hubportal/components';
import waitPermissions from 'utils/hoc/waitPermissions';
import { useQuery } from '@tanstack/react-query';
import { getWorkforceKpi } from 'utils/network/apis';
import { OpsAssociate } from 'src/models/ops-associate';
import {
  SortDirection,
  sortActivities,
  sortByNameOrPunchIn,
  sortByNumericField,
  sortDataSetByTime,
} from 'utils/helpers';
import {
  OATableColumns,
  OaActivityOrder,
  RiderActivityOrder,
  RiderTableColumns,
} from 'utils/constants';
import { Rider } from 'src/models/rider';
import { LearnMore } from 'components/LearnMore';
import { LearnMoreModal } from 'components/LearnMoreModal';
import { useLocation, useNavigate } from 'react-router-dom';
import RidersTable from './rides-table';
import OpsAssociatesTable from './ops-associates-table';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';

enum EmployeeLevelMetricFilters {
  AllMetric = 'all',
  OAMetric = 'oa',
  RiderMetric = 'rider',
}

enum EmployeeActivityFilters {
  WorkingNow = 'working_now',
  NotWorkingNow = 'not_working_now',
  All = 'all',
}

const EMPLOYEE_ACTIVITY_FILTERS_ARRAY = Object.values(EmployeeActivityFilters);
const EMPLOYEE_LEVEL_METRICS_ARRAY = Object.values(EmployeeLevelMetricFilters);

const EmployeeLevelFC = (props: { hubSlug: string }): JSX.Element => {
  const { hubSlug } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { isAllowed, isAllowedStrict } = usePermissions();

  const [learnMoreModal, setLearnMoreModal] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState(
    EmployeeLevelMetricFilters.AllMetric
  );
  const [selectedEmployeeActivity, setSelectedEmployeeActivity] = useState(
    EmployeeActivityFilters.WorkingNow
  );

  const [opsAssociates, setOpsAssociates] = useState<OpsAssociate[]>([]);
  const [riders, setRiders] = useState<Rider[]>([]);
  const opsAssociatesTableRef = useRef<HTMLDivElement>(null);
  const ridersTableRef = useRef<HTMLDivElement>(null);

  const tooltipSections = [
    {
      title: (
        <div className="flex text-flinkGray-light">
          <Icon type="package" />
          <span className="ml-2">{t('learn_more.hub_metrics')}</span>
        </div>
      ),
      answer: (
        <div>
          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.hub_metrics.hours_worked.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.hub_metrics.hours_worked.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.hub_metrics.break_duration.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.hub_metrics.break_duration.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.hub_metrics.orders_picked.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.hub_metrics.orders_picked.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.hub_metrics.avg_picking_time.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.hub_metrics.avg_picking_time.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.hub_metrics.pre_delivery_issue.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.hub_metrics.pre_delivery_issue.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.hub_metrics.post_delivery_issue.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.hub_metrics.post_delivery_issue.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.hub_metrics.skus_inbounded.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.hub_metrics.skus_inbounded.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.hub_metrics.skus_checked.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.hub_metrics.skus_checked.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.hub_metrics.skus_restocked.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.hub_metrics.skus_restocked.description')}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="flex text-flinkGray-light">
          <Icon type="bicycle" />
          <span className="ml-2">{t('learn_more.riding_metrics')}</span>
        </div>
      ),
      answer: (
        <div>
          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.riding_metrics.hours_worked.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.riding_metrics.hours_worked.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.riding_metrics.break_duration.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.riding_metrics.break_duration.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.riding_metrics.orders_delivered.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.riding_metrics.orders_delivered.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.riding_metrics.deliveries_per_hour.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.riding_metrics.deliveries_per_hour.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.riding_metrics.acceptance_rate.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.riding_metrics.acceptance_rate.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.riding_metrics.avg_time_at_customer.title')}
            </div>
            <div className="font-light text-sm">
              {t('learn_more.riding_metrics.avg_time_at_customer.description')}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.riding_metrics.trip_on_time_compliance.title')}
            </div>
            <div className="font-light text-sm">
              {t(
                'learn_more.riding_metrics.trip_on_time_compliance.description'
              )}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t('learn_more.riding_metrics.unaccounted_time_offline.title')}
            </div>
            <div className="font-light text-sm">
              {t(
                'learn_more.riding_metrics.unaccounted_time_offline.description'
              )}
            </div>
          </div>

          <div className="mt-6">
            <div className="font-medium mb-1">
              {t(
                'learn_more.riding_metrics.avg_rider_preparation_time_in_seconds.title'
              )}
            </div>
            <div className="font-light text-sm">
              {t(
                'learn_more.riding_metrics.avg_rider_preparation_time_in_seconds.description'
              )}
            </div>
          </div>
        </div>
      ),
    },
  ];

  const { data, refetch } = useQuery({
    queryKey: ['get-workforce-kpi', selectedMetric],
    queryFn: () =>
      getWorkforceKpi(hubSlug, {
        workforce_type: selectedMetric === 'all' ? '' : selectedMetric,
      }),
    refetchInterval: 10000,
  });

  useEffect(() => {
    refetch();
  }, [selectedMetric]);

  const [oaSortBy, setoaSortBy] = useState<TableSortBy>({
    column: '',
    type: null,
  });

  const [riderSortBy, setRiderSortBy] = useState<TableSortBy>({
    column: '',
    type: null,
  });

  const isPerformanceTabEnabled = useMemo(() => {
    return (
      isAllowed(Permission.READ_COMPLIANCE_ALL) ||
      isAllowedStrict(Permission.READ_COMPLIANCE_ALLOWED, hubSlug) ||
      isAllowedStrict(Permission.READ_COMPLIANCE_LIMITED, hubSlug) ||
      isAllowedStrict(Permission.READ_PERFORMANCE_LIMITED, hubSlug)
    );
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const kpi = params.get('kpi');
    const metric = params.get('metric');
    const sortBy: SortDirection = params.get('sort_by') as SortDirection;

    const handleScroll = (): void => {
      if (kpi === 'riding' && ridersTableRef.current) {
        setRiderSortBy({
          column: metric || '',
          type: sortBy || null,
        });
        ridersTableRef.current.scrollIntoView({ behavior: 'smooth' });
      } else if (kpi === 'inside-hub' && opsAssociatesTableRef.current) {
        opsAssociatesTableRef.current.scrollIntoView({ behavior: 'smooth' });
        setoaSortBy({
          column: metric || '',
          type: sortBy || null,
        });
      }
    };

    const observer = new MutationObserver((mutations, observerInstance) => {
      handleScroll();
      if (ridersTableRef.current || opsAssociatesTableRef.current) {
        observerInstance.disconnect();
        navigate({
          pathname: location.pathname,
        });
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [location.search]);

  const sortOAColumns = (Ops: OpsAssociate[]): OpsAssociate[] => {
    let sortedData: OpsAssociate[];
    switch (oaSortBy.column) {
      case OATableColumns.NAME:
      case OATableColumns.PUNCH_IN:
        sortedData = sortByNameOrPunchIn(Ops, oaSortBy.column, oaSortBy.type);
        break;
      case OATableColumns.ACTIVITY:
        sortedData = sortActivities(Ops, OaActivityOrder, oaSortBy.type);
        break;
      case OATableColumns.HOURS_WORKED:
        sortedData = sortDataSetByTime(
          Ops,
          oaSortBy.column,
          oaSortBy.type,
          'h'
        );
        break;
      case OATableColumns.BREAK_DURATION:
        sortedData = sortDataSetByTime(
          Ops,
          oaSortBy.column,
          oaSortBy.type,
          'm'
        );
        break;
      case OATableColumns.ORDERS_PICKED:
      case OATableColumns.AVG_PICKING_SPEED_PER_ITEM_IN_SECONDS:
      case OATableColumns.PRE_DELIVERY_ISSUE_RATE:
      case OATableColumns.POST_DELIVERY_ISSUE_RATE:
      case OATableColumns.INBOUNDED_SKUS:
      case OATableColumns.CHECKED_SKUS:
      case OATableColumns.RESTOCKED_SKUS:
        sortedData = sortByNumericField(Ops, oaSortBy.column, oaSortBy.type);
        break;
      default:
        sortedData = [...Ops];
    }
    return sortedData;
  };

  const sortRiderColumns = (allRiders: Rider[]): Rider[] => {
    let sortedData: Rider[];
    switch (riderSortBy.column) {
      case RiderTableColumns.NAME:
      case RiderTableColumns.PUNCH_IN:
        sortedData = sortByNameOrPunchIn(
          allRiders,
          riderSortBy.column,
          riderSortBy.type
        );
        break;
      case RiderTableColumns.ACTIVITY:
        sortedData = sortActivities(
          allRiders,
          RiderActivityOrder,
          riderSortBy.type
        );
        break;
      case RiderTableColumns.HOURS_WORKED:
        sortedData = sortDataSetByTime(
          allRiders,
          riderSortBy.column,
          riderSortBy.type,
          'h'
        );
        break;
      case RiderTableColumns.BREAK_DURATION:
        sortedData = sortDataSetByTime(
          allRiders,
          riderSortBy.column,
          riderSortBy.type,
          'm'
        );
        break;
      case RiderTableColumns.ORDERS_DELIVERED:
      case RiderTableColumns.DELIVERIES_PER_HOUR:
      case RiderTableColumns.ACCEPTANCE_RATE:
      case RiderTableColumns.AVG_TIME_AT_CUSTOMER_IN_SECONDS:
      case RiderTableColumns.TRIP_ON_TIME_COMPLIANCE:
      case RiderTableColumns.UNACCOUNTED_TIME_OFFLINE:
      case RiderTableColumns.AVG_RIDER_PREPARATION_TIME_IN_SECONDS:
        sortedData = sortByNumericField(
          allRiders,
          riderSortBy.column,
          riderSortBy.type
        );
        break;
      default:
        sortedData = [...allRiders];
    }
    return sortedData;
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const filterAssociates = (associates, activityStatus) => {
      return associates.filter(({ activity }) =>
        activityStatus === EmployeeActivityFilters.WorkingNow
          ? activity !== '' && activity !== 'offline'
          : activityStatus === EmployeeActivityFilters.NotWorkingNow
          ? activity === '' || activity === 'offline'
          : true
      );
    };

    const updateState = (): void => {
      if (data?.ops_associates) {
        const filteredOAs = filterAssociates(
          data?.ops_associates,
          selectedEmployeeActivity
        );
        setOpsAssociates(sortOAColumns(filteredOAs));
      }

      if (data?.riders) {
        const filteredRiders = filterAssociates(
          data?.riders,
          selectedEmployeeActivity
        );
        setRiders(sortRiderColumns(filteredRiders));
      }
    };

    if (data) {
      updateState();
    }
  }, [selectedEmployeeActivity, data, selectedMetric]);

  useEffect(() => {
    setRiders(sortRiderColumns(riders));
  }, [riderSortBy]);

  useEffect(() => {
    setOpsAssociates(sortOAColumns(opsAssociates));
  }, [oaSortBy]);

  return (
    <div className="text-white pb-8 pl-8 pt-4 pr-8 mr-8">
      <div className="text-xl font-bold mb-2">
        {t('employee_level_metrics.header')}
      </div>
      <div className="mb-4 font-normal text-s">
        {t('employee_level_metrics.subheader')}
      </div>
      <div className="flex">
        <div className="w-48 mr-4 bg-primary rounded-lg">
          <Select
            data-testid="filterButton"
            variant={SelectVariant.secondary}
            label=""
            placeholder=""
            value={{
              id: selectedMetric,
              label: t(
                `employee_level_metrics.option_filter.${selectedMetric}`
              ),
            }}
            onChange={({ id: selectId }) => {
              setSelectedMetric(selectId as EmployeeLevelMetricFilters);
            }}
          >
            {EMPLOYEE_LEVEL_METRICS_ARRAY.map((metric) => (
              <SelectItem id={metric} label={`${metric}`} key={metric}>
                {t(`employee_level_metrics.option_filter.${metric}`)}
              </SelectItem>
            ))}
          </Select>
        </div>
        <div className="w-48 bg-primary rounded-lg">
          <Select
            data-testid="activityFilterButton"
            label=""
            placeholder=""
            value={{
              id: selectedEmployeeActivity,
              label: t(
                `employee_level_metrics.activity_filter.${selectedEmployeeActivity}`
              ),
            }}
            onChange={({ id: selectId }) => {
              setSelectedEmployeeActivity(selectId as EmployeeActivityFilters);
            }}
          >
            {EMPLOYEE_ACTIVITY_FILTERS_ARRAY.map((metric) => (
              <SelectItem id={metric} label={`${metric}`} key={metric}>
                {t(`employee_level_metrics.activity_filter.${metric}`)}
              </SelectItem>
            ))}
          </Select>
        </div>
      </div>

      <div className="overflow-x-scroll">
        {selectedMetric === 'oa' && (
          <OpsAssociatesTable
            data={opsAssociates}
            sortBy={oaSortBy}
            onSortChange={(sb) => setoaSortBy(sb)}
            ref={opsAssociatesTableRef}
            isPerformanceTabEnabled={isPerformanceTabEnabled}
          />
        )}
        {selectedMetric === 'rider' && (
          <RidersTable
            data={riders}
            sortBy={riderSortBy}
            onSortChange={(sb) => setRiderSortBy(sb)}
            ref={ridersTableRef}
            isPerformanceTabEnabled={isPerformanceTabEnabled}
          />
        )}
        {selectedMetric === 'all' && (
          <>
            <OpsAssociatesTable
              data={opsAssociates}
              sortBy={oaSortBy}
              onSortChange={(sb) => setoaSortBy(sb)}
              ref={opsAssociatesTableRef}
              isPerformanceTabEnabled={isPerformanceTabEnabled}
            />
            <RidersTable
              data={riders}
              sortBy={riderSortBy}
              onSortChange={(sb) => setRiderSortBy(sb)}
              ref={ridersTableRef}
              isPerformanceTabEnabled={isPerformanceTabEnabled}
            />
          </>
        )}
      </div>
      <LearnMore onClick={() => setLearnMoreModal(true)} />
      {learnMoreModal && (
        <LearnMoreModal
          open={learnMoreModal}
          onClose={() => setLearnMoreModal(false)}
          tooltipSections={tooltipSections}
          modalTitle={t('learn_more.title')}
          subtitle={t('learn_more.subtitle')}
        />
      )}
    </div>
  );
};

const EmployeeLevel = waitPermissions(withMFA(EmployeeLevelFC));
export { EmployeeLevel };
