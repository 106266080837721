import { useEffect } from 'react';
import sdk from '@hubportal/sdk';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import useStore from 'utils/store';

export enum Permission {
  EMPLOYEE_ACTIVITY = 'read:employee_activity:all',
  READ_COMPLIANCE_ALL = 'read:compliance:all',
  READ_COMPLIANCE_ALLOWED = 'read:compliance:allowed',
  READ_COMPLIANCE_LIMITED = 'read:compliance:limited',
  READ_PERFORMANCE_LIMITED = 'read:performance:limited',
}

type PermissionsResult = {
  permissionsReady: boolean;
  isAllowed(permission: Permission): boolean;
  isAllowedStrict(permission: Permission, hub?: string): boolean;
};

const usePermissions = (): PermissionsResult => {
  const {
    permissions,
    permissionsReady,
    setPermissions,
    allowedHubs,
    setAllowedHubs,
  } = useStore();

  useEffect(() => {
    const parseUserPermissions = async (): Promise<void> => {
      try {
        const token = await sdk.getAuthClient().getTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        const decodedToken =
          jwtDecode<
            JwtPayload & {
              permissions?: string[];
              allowed_hubs?: string[];
              sub: string;
            }
          >(token) || {};
        const { permissions: jwtPermissions = [], allowed_hubs: hubs = [] } =
          decodedToken;

        const authorized = jwtPermissions.reduce(
          (acc, permission) => ({ ...acc, [permission]: true }),
          {}
        );

        setAllowedHubs(hubs);
        setPermissions(authorized);
      } catch (err) {
        sdk.reportError(err, '@hubportal/flow-management', {
          message: 'usePermissions: parse user permissions error',
        });
      }
    };

    if (!permissionsReady) {
      parseUserPermissions();
    }
  }, []);

  return {
    permissionsReady,
    isAllowed: (permission) => {
      return permissions[permission] || false;
    },
    isAllowedStrict: (permission, hub) => {
      if (permissions[permission] && hub) {
        return allowedHubs.some((h) => hub?.startsWith(h));
      }
      return false;
    },
  };
};

export default usePermissions;
