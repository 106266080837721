import axios, { AxiosRequestConfig, Method } from 'axios';
import format from 'string-format';
import qs from 'qs';
import statics from './statics.json';
import sdk from '@hubportal/sdk';

// Abort request if it takes longer than 30 seconds
const REQUEST_TIMEOUT = 30 * 1000;

interface RequestOptions {
  data?: any;
  customHeaders?: any;
  interpolate?: any;
  withCredentials?: boolean;
  meta?: any;
}

export enum Origin {
  LastMile = 'last-mile',
  OpsStateManager = 'ops-state-manager',
}

export const fetch = async (
  type: Method,
  path: keyof typeof statics.endpoints,
  options: RequestOptions = {},
  origin: Origin = Origin.LastMile
): Promise<any> => {
  const headers = {
    'Content-Type': 'application/json',
    ...(options.customHeaders || {}),
  };

  const baseURL =
    origin === Origin.OpsStateManager
      ? process.env.REACT_APP_API_GATEWAY_OPS_STATE_MANAGER
      : process.env.REACT_APP_API_GATEWAY_BASE_URL;

  let url = statics.endpoints[path as keyof typeof statics.endpoints];

  if (options.interpolate) {
    url = format(url, options.interpolate);
  }

  if (type === 'GET' && options.data) {
    url += `?${qs.stringify(options.data, {
      encode: true,
      indices: false,
      arrayFormat: 'comma',
    })}`;
  }

  let config: AxiosRequestConfig = {
    url,
    baseURL,
    headers,
    method: type,
    responseType: 'json',
    timeout: REQUEST_TIMEOUT,
    withCredentials: options.withCredentials,
  };

  if (type !== 'GET') {
    config = { ...config, data: options.data };
  }

  try {
    const response = await axios(config);
    return response.data;
  } catch (err: any) {
    sdk.reportError(
      new Error(`[Axios error]: Message: ${err}`),
      '@hubportal/flow-management',
      {
        status: err?.response?.status,
        data: err?.response?.data,
        message: err?.message,
      }
    );
    throw err;
  }
};
