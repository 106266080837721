import { PropsWithChildren, useEffect, useState } from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { tokenMiddleware } from 'utils/network/tokenMiddleware';
import { Main } from 'layouts/main';
import { FlowManagementHubLevel } from './pages/flow-management-hub-level';
import axios from 'axios';

import './tailwind.css';
import '@hubportal/components/index.css';
import LiveSteering from './pages/live-steering';
const ProtectedRoute = ({
  authClient,
  children,
}: PropsWithChildren<any>): JSX.Element => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.interceptors.request.use(tokenMiddleware(authClient));
    setLoading(false);
  }, []);

  if (loading) {
    return <div>...</div>;
  }

  return children;
};

const App = ({ authClient }: PropsWithAuth0Client): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute authClient={authClient}>
              <Main />
            </ProtectedRoute>
          }
        >
          <Route
            path="activity"
            element={<FlowManagementHubLevel authClient={authClient} />}
          ></Route>
          <Route
            path="activity/employee-level"
            element={<LiveSteering authClient={authClient} />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
