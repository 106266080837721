import { forwardRef, useMemo } from 'react';
import {
  Icon,
  Table,
  TableSortBy,
  TableVariant,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@hubportal/components';
import { useTranslation } from 'react-i18next';
import { CalculatedTimeSince } from './calculate-time-since';
import { JobTitles, OAStates, OATableColumns } from 'utils/constants';
import {
  capitalizeOrUppercaseJobTitle,
  convertToTimeRange,
  formatNumberToPercentage,
  formatPunchInTime,
  getOAActivityColor,
  isShiftStartLaterThanNow,
} from 'utils/helpers';
import PickingPriorityColumn from 'components/PickingPriorityColumn';
import useStore from 'utils/store';
import { OpsAssociate } from 'src/models/ops-associate';
import { useEmployeePriorityMap } from 'src/hooks/useEmployeePriorityMap';

interface OpsAssociatesTableProps {
  data: OpsAssociate[];
  sortBy: TableSortBy;
  onSortChange: (sb: TableSortBy) => void;
  isPerformanceTabEnabled: boolean;
  isPickingPriorityEnabled: boolean;
}

const OpsAssociatesTable = forwardRef<HTMLDivElement, OpsAssociatesTableProps>(
  (
    {
      data,
      sortBy,
      onSortChange,
      isPerformanceTabEnabled,
      isPickingPriorityEnabled,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const {
      selectedHub: { slug: hubSlug },
    } = useStore();

    const employeePriorityMap = useEmployeePriorityMap(hubSlug);

    const currentColumns = Object.values(OATableColumns);

    const activeColumns = useMemo(
      () =>
        currentColumns.filter(
          (column) =>
            isPickingPriorityEnabled ||
            column !== OATableColumns.PICKING_PRIORITY
        ),
      [isPickingPriorityEnabled]
    );

    const renderCellContent = (
      employee: OpsAssociate,
      column: string
    ): JSX.Element => {
      switch (column) {
        case OATableColumns.NAME:
          return (
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                window.open(
                  `/workforce/${employee.auth0_id}/${
                    isPerformanceTabEnabled ? 'performance' : ''
                  }`,
                  '_blank'
                );
              }}
            >
              <div className="text-base flex items-center mb-2 w-36">
                {employee.job_title && (
                  <span className="mr-1 text-xs font-normal">
                    [{capitalizeOrUppercaseJobTitle(employee.job_title)}]
                  </span>
                )}
                <span className="truncate">{employee.name}</span>
              </div>
              {employee.shift_start &&
                employee.shift_end &&
                employee.job_title !== JobTitles.EXT && (
                  <div className="text-[#BFBFBF] text-sm font-normal">
                    {convertToTimeRange(
                      employee.shift_start,
                      employee.shift_end
                    )}
                  </div>
                )}
            </div>
          );
        case OATableColumns.ACTIVITY:
          return (
            <div className="flex flex-col">
              {employee.activity && (
                <div className="flex items-baseline mb-2 text-base font-normal w-28">
                  <span
                    className={`w-2 h-2 rounded mr-2 shrink-0 ${getOAActivityColor(
                      employee.activity
                    )}`}
                  />
                  <span className="truncate">
                    {t(
                      `employee_metrics.oa_table.activities.${employee.activity}`
                    )}
                  </span>
                </div>
              )}
              {employee.in_state_since &&
                employee.activity !== OAStates.OFFLINE && (
                  <div className="text-[#BFBFBF] text-sm font-normal">
                    <CalculatedTimeSince
                      inStateSince={employee.in_state_since}
                    />
                  </div>
                )}
            </div>
          );
        case OATableColumns.PICKING_PRIORITY:
          return (
            <PickingPriorityColumn
              employeeId={employee.employee_id}
              pickerPriority={employeePriorityMap[employee.employee_id]}
            />
          );
        case OATableColumns.PUNCH_IN:
          return (
            <div className="flex h-full items-center">
              {employee.punch_in ? (
                <div>{formatPunchInTime(employee.punch_in)}</div>
              ) : !isShiftStartLaterThanNow(employee.shift_start as string) ? (
                <div className="py-1 px-2 bg-orange-400 text-gray rounded">
                  {t(`employee_metrics.oa_table.col.missing`)}
                </div>
              ) : null}
            </div>
          );
        case OATableColumns.AVG_PICKING_SPEED_PER_ITEM_IN_SECONDS:
          return (
            <>
              {employee.avg_picking_speed_per_item_in_seconds && (
                <div className="flex h-full items-center">
                  {employee.avg_picking_speed_per_item_in_seconds}secs
                </div>
              )}
            </>
          );
        case OATableColumns.PRE_DELIVERY_ISSUE_RATE:
          return (
            <>
              {employee.pre_delivery_issue_rate && (
                <div className="flex h-full items-center">
                  {formatNumberToPercentage(
                    employee.pre_delivery_issue_rate ?? 0
                  )}
                </div>
              )}
            </>
          );
        case OATableColumns.POST_DELIVERY_ISSUE_RATE:
          return (
            <>
              {employee.post_delivery_issue_rate && (
                <div className="flex h-full items-center">
                  {formatNumberToPercentage(
                    employee.post_delivery_issue_rate ?? 0
                  )}
                </div>
              )}
            </>
          );
        default:
          return (
            <div className="flex h-full items-center">{employee[column]}</div>
          );
      }
    };

    const renderRow = (employee: OpsAssociate): JSX.Element[] =>
      activeColumns.map((column) => (
        <Td
          hoverable={false}
          key={column}
          selected={false}
          overflowHidden={false}
        >
          <span className="text-base flex h-full items-center hover:cursor-default">
            {renderCellContent(employee, column)}
          </span>
        </Td>
      ));

    const renderHeaders = (): JSX.Element[] =>
      activeColumns?.map((column) => (
        <Th key={column} name={column} sortable>
          <div className="mr-4 w-[80px] whitespace-normal overflow-wrap">
            {t(`employee_metrics.oa_table.${column}`)}
          </div>
        </Th>
      ));

    return (
      <div className="mt-8">
        <div className="mb-4 flex">
          <Icon type="package" />
          <span className="ml-2" data-testid="insideHubTable">
            {t(`employee_level_metrics.option_filter.oa`)}
          </span>
        </div>
        <div className="flex flex-col pr-4" ref={ref}>
          <Table
            variant={TableVariant.secondary}
            sortLabels={{
              asc: t('sort_ascending'),
              desc: t('sort_descending'),
            }}
            sortBy={sortBy}
            onSortChange={onSortChange}
            gridTemplateColumns={`184px ${'136px '.repeat(
              activeColumns.length ? activeColumns.length - 2 : 0
            )}minmax(120px, 1fr)`}
          >
            <Thead>
              <Tr>{renderHeaders()}</Tr>
            </Thead>
            <Tbody>
              {data.map((employee, i) => (
                <Tr
                  key={i}
                  hoverable={false}
                  variant={i % 2 === 0 ? 'row-even' : 'row-odd'}
                >
                  {renderRow(employee)}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </div>
    );
  }
);

export default OpsAssociatesTable;
