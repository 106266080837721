import { Icon } from '@hubportal/components';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

export const LearnMore = ({ onClick }: { onClick: () => any }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div
      className={classnames([
        'fixed',
        'bottom-8',
        'right-8',
        'p-4',
        'flex',
        'gap-2',
        'items-center',
        'rounded-full',
        'bg-pink-400',
        'text-white',
        'title-s',
        'cursor-pointer',
        'shadow-lg',
        'shadow-black',
        'z-10',
      ])}
      style={{ bottom: '2rem', right: '2rem' }}
      onClick={() => onClick()}
    >
      <Icon type="question" />
      {t('learn_more')}
    </div>
  );
};
