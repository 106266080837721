import { Icon, ProgressBar, ProgressBarProps } from '@hubportal/components';

interface CheckProgressProps {
  title: string;
  completedPercentage: number;
  completedChecksCount: number;
  allChecksCount: number;
  progressColor: string;
}

const CheckProgress: React.FC<CheckProgressProps> = ({
  title,
  completedPercentage,
  completedChecksCount,
  allChecksCount,
  progressColor,
}: CheckProgressProps) => {
  return (
    <div className={`p-2 ${completedPercentage === 100 ? 'opacity-50' : ''}`}>
      <h3>{title}</h3>
      <div className="flex flex-col items-start">
        <div className="w-full py-1">
          <ProgressBar
            width={completedPercentage as ProgressBarProps['width']}
            color={progressColor}
            height={1}
            backgroundColor="bg-[#3A4045]"
          />
        </div>
        <div>
          {completedPercentage === 100 ? (
            <span className="flex items-center">
              <span className="text-white">
                {`${completedChecksCount}/${allChecksCount}`}
              </span>
              <span className="text-[#008040] ml-2">
                <Icon type="verification" />
              </span>
            </span>
          ) : (
            <span
              className={`${
                completedChecksCount ? 'text-[#BFBFBF]' : 'text-white'
              }`}
            >
              {`${completedChecksCount}/${allChecksCount}`}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckProgress;
