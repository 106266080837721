import { getOAActivityColor, getRiderActivityColor } from 'utils/helpers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalculatedTimeSince } from 'src/pages/employee-level/calculate-time-since';

export interface Workforce {
  auth0_id: string;
  first_name: string;
  last_name: string;
  activity: string;
  in_state_since: string | null;
}

type WorkforceSectionProps = {
  workforceList: GroupedWorforce[];
  hasRider?: boolean;
};

export interface GroupedWorforce {
  status: string;
  workforce: Workforce[];
}

export function WorkforceSection({
  workforceList,
  hasRider = false,
}: WorkforceSectionProps): JSX.Element {
  const [hoveredActivity, setHoveredActivity] = useState<string | null>(null);
  const { t } = useTranslation();
  return (
    <>
      {workforceList.map((workforceInfo, i) => (
        <div key={i} className="p-2 relative">
          <div
            className="text-center p-1 relative cursor-pointer hover:bg-black rounded-md"
            onMouseEnter={() => setHoveredActivity(workforceInfo.status)}
            onMouseLeave={() => setHoveredActivity(null)}
          >
            <div className="text-xl text-[#BFBFBF] font-bold">
              {workforceInfo.workforce.length}
            </div>
            <div className="text-sm font-light">
              {t(`hub_metrics.status.${workforceInfo.status}`)}
            </div>
          </div>
          {hoveredActivity === workforceInfo.status && (
            <div
              className={`absolute top-full bg-white p-4 rounded-md text-black text-left min-w-max ${
                i === workforceList.length - 1 ? 'right-2' : 'left-2'
              }`}
            >
              <div className="text-base font-bold mb-4 flex items-center">
                <span>{t(`hub_metrics.status.${workforceInfo.status}`)}</span>
                <span
                  className={`w-3 h-3 rounded-full ml-2 shrink-0 ${
                    hasRider
                      ? getRiderActivityColor(workforceInfo.status)
                      : getOAActivityColor(workforceInfo.status)
                  }`}
                />
              </div>
              <ul className="text-sm">
                {workforceInfo.workforce.length === 0
                  ? hasRider
                    ? t('hub_metrics.status.not_found_rider')
                    : t('hub_metrics.status.not_found_oas')
                  : workforceInfo.workforce.map((user, idx) => (
                      <li key={idx} className="mb-2">
                        {`${user.first_name} ${user.last_name.charAt(0)}.`}
                        {user.in_state_since && (
                          <>
                            {` - `}
                            <CalculatedTimeSince
                              inStateSince={user.in_state_since}
                            />
                          </>
                        )}
                      </li>
                    ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </>
  );
}

export default WorkforceSection;
